import {Button, Stack, Box, Typography} from '@mui/material';
import {styled} from '@mui/system';

export const LoadingContainer = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    height: '100vh',
    width: '100vw',
  },
  [theme.breakpoints.up('sm')]: {
    height: '400px',
    width: '600px',
  },
}));

export const EventContainer = styled(Box)(({theme}) => ({
  padding: '16px 20px',
  margin: 'auto',
  paddingTop: '24px',
  [theme.breakpoints.down('xl')]: {
    maxWidth: '722px',
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: '1048px',
  },
}));


export const AppointmentsListContainer = styled(Stack)(({theme}) => ({
  marginTop: '16px',
  flexWrap: 'wrap',
}));

export const TypographyDescription = styled(Typography)(({theme}) => ({
  color: theme.palette.text.tertiary,
  fontWeight: '400',
  fontSize: '.875rem',
  lineHeight: '1.25rem',
  marginTop: '12px',
}));

export const TypographyName = styled(Typography)(({theme}) => ({
  fontWeight: '600',
  fontSize: '1.5rem',
  lineHeight: '2rem',
  marginTop: '8px',
  color: theme.palette.text.primary,
}));
export const GlobalItemContainer = styled(Button)(({theme}) => ({
  'boxSizing': 'border-box',
  'position': 'relative',

  'display': 'flex',
  'flexDirection': 'column',
  'alignItems': 'flex-start',
  'padding': '16px',
  'gap': '10px',

  'width': '320px',
  'height': '160px',

  'color': `${theme.palette.text.primary}`,
  'background': `${theme.palette.background.default}`,
  'border': `1px solid ${theme.palette.common.swatch['30']}`,
  'borderRadius': '16px',

  'flex': 'none',
  'flexGrow': '0',
  'justifyContent': 'space-between',

  'overflow': 'hidden',
  '&:hover': {
    backgroundColor: theme.palette.background.hover,
  },
  [theme.breakpoints.down('md')]: {
    'margin': ' 8px auto',
  },
  [theme.breakpoints.up('md')]: {
    'marginBottom': '16px',
    'marginRight': ' 16px',
  },
}));

export const TypographyH4 = styled(Typography)(({theme}) => ({
  margin: 0,
  marginTop: '2px',
  fontSize: '1rem',
  fontWeight: '600',
  lineHeight: '1.5rem',
  overflowWrap: 'anywhere',
  paddingRight: '4px',
}));
