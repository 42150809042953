
import React, {useMemo} from 'react';

import {useSearchParams} from 'react-router-dom';

import {
  fetchAppointment as fetchAppointmentExt,
  getApptDetail as fetchApptDetailsExt,
} from 'Api/Calendar';
import {
  fetchAppointment as fetchApptInternal,
  fetchApptData as fetchApptDetailsInternal,
} from 'Api/ZCalendar';
import Appointments from 'Components/Appointments';
import ApiContext from 'Components/Reschedulings/ApiContext';
import HostContext from 'HostContext';
import {getToken} from 'Utils/integration';


/**
 * Adapter for new /user/appt style URLs
 * @param {Object} props
 * @param {Boolean} props.isHost
 * @return {ReactElement}
 */
export function NewAppointmentsUrlAdapter(props) {
  const calendarId = props.calendarId;
  const appointmentId = props.appointmentId;

  const [searchParams] = useSearchParams();

  let isHost = false;
  if (searchParams.get('host')?.toLowerCase() === 'true') {
    try {
      if (getToken()) {
        isHost = true;
      }
    } catch (e) {
      console.debug('Error getting host');
    }
  }

  const contextValue = useMemo(() => {
    return {
      captchaUser: calendarId,
      loadAppt: async (calId, apptId, query) => {
        return await fetchApptDetailsInternal(calendarId, apptId, query);
      },
      loadApptAvail: async (calId, apptId, query) => {
        const queryParams = {
          ...query,
          debugMode: true,
        };
        return await fetchApptInternal(calendarId, apptId, queryParams);
      },
    };
  }, [calendarId]);

  const contextValueForNotHost = useMemo(() => {
    return {
      captchaUser: calendarId,
      loadAppt: async (_, apptId, query) => {
        return await fetchApptDetailsExt(calendarId, apptId, query);
      },
      loadApptAvail: async (_, apptId, query) => {
        return await fetchAppointmentExt(calendarId, apptId, query);
      },
    };
  }, [calendarId]);

  if (isHost) {
    return (
      <HostContext.Provider value={{
        isHost: isHost,
      }}>
        <ApiContext.Provider
          value={contextValue}
        >
          <Appointments
            calendarId={calendarId}
            appointmentId={appointmentId}
          />
        </ApiContext.Provider>
      </HostContext.Provider>
    );
  }

  return (
    <HostContext.Provider value={{
      isHost: isHost,
    }}>
      <ApiContext.Provider
        value={contextValueForNotHost}
      >
        <Appointments
          calendarId={calendarId}
          appointmentId={appointmentId}
        />
      </ApiContext.Provider>
    </HostContext.Provider>
  );
}
