import React, {useMemo} from 'react';

import {useLocation, useParams} from 'react-router-dom';

import HostContext from '../../HostContext';

import {NewAppointmentsUrlAdapter} from 'Components/Appointments/Adapters';
import GlobalLanding from 'Components/GlobalLanding';

/**
 * Component to parse URL params and display corresponding component
 * @param {Object} props
 * @return {ReactElement}
 */
export default function ParseAppt() {
  const {pathname} = useLocation();
  const {apptSlug, userSlug} = useParams();

  const paramData = useMemo(() => {
    // userSlug work as calId, and apptSlug work as apptId
    return (userSlug && apptSlug) ? [userSlug, apptSlug]: [userSlug];
  }, [userSlug, apptSlug]);


  // New shortened slugs
  if (paramData?.length === 2) {
    if (pathname.includes('/d/')) {
      // When booking team appts, user slug / calendarId should have form 'd/<randomId>'
      return <NewAppointmentsUrlAdapter calendarId={`d/${paramData[0]}`} appointmentId={paramData[1]}/>;
    } else if (pathname.includes('/t/')) {
      return <NewAppointmentsUrlAdapter calendarId={`t/${paramData[0]}`} appointmentId={paramData[1]}/>;
    } else {
      // Booking individual appts
      return <NewAppointmentsUrlAdapter calendarId={paramData[0]} appointmentId={paramData[1]}/>;
    }
  } else {
    return (
      <HostContext.Provider value={{
        isHost: false,
      }}>
        <GlobalLanding calendarId={paramData[0]}/>
      </HostContext.Provider>
    );
  };
}
