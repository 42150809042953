import React from 'react';

import {useTheme} from '@emotion/react';
import {Circle} from '@mui/icons-material';
import {
  Box,
  SvgIcon,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {GlobalItemContainer, TypographyH4} from './Style';

import {ReactComponent as ChevronRight} from 'assets/svg/chevron-right-filled.svg';
import {ReactComponent as ClockOutlined} from 'assets/svg/clock-outlined.svg';
import {ReactComponent as ProfileIcon} from 'assets/svg/profile-outlined.svg';
import {ReactComponent as TeamIcon} from 'assets/svg/team-outlined.svg';
import OverflowTip from 'Components/OverflowTooltip';
import {TemplatePreview} from 'Components/TemplatePreview/TemplatePreview';
import {resetApptStore} from 'Store/BookAppointmentsStore';
import {getSafeScheduleColorValue} from 'Utils';
import {renderDuration} from 'Utils/CardDisplay';
import zoomPalette from 'zoomPalette';

/**
 * List Appointments Landing Page Component
 * @param {Object} props
 * @param {Object} props.appointment
 * @param {String} props.calendarId
 * @return {ReactElement}
 */
export default function GlobalLandingItem(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    appointment,
    calendarId,
  } = props;

  const color = getSafeScheduleColorValue(appointment?.color);

  const {t} = useTranslation();

  const handleClick = () => {
    // clear existing appt data before navigating to the next page
    dispatch(resetApptStore());
    // try to use server-generated link first to support vanity URL
    if (appointment?.bookingLink) {
      const {pathname, search} = new URL(appointment.bookingLink);
      navigate({
        pathname,
        search,
      });
    } else if (appointment) {
      navigate(`/${calendarId}/${appointment.slug || appointment.id}`);
    }
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const overflowTipPlacement = (isSmallScreen) ? 'bottom' : 'right';

  return (
    <GlobalItemContainer
      tabIndex={0}
      onClick={handleClick}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%',
        fontSize: '1rem',
      }}>
        <Box sx={{display: 'flex'}}>
          {color && <Circle sx={{color: color, fontSize: 28, mr: '10px', flexShrink: 0}} />}
          <OverflowTip
            lineClamp={2}
            content={appointment.summary || t('common.noTitle')}
            render={TypographyH4}
            placement={overflowTipPlacement}
          />
        </Box>
        <div style = {{padding: '5px'}}>
          <SvgIcon fontSize="inherit" component={ChevronRight} inheritViewBox sx={{marginRight: '.75rem'}} />
        </div>
      </Box>

      <Box sx={{
        flexGrow: 1,
        overflowY: 'auto',
      }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <SvgIcon
              fontSize="inherit"
              component={ClockOutlined}
              inheritViewBox
            />
            <Typography variant="body2">
              {renderDuration(appointment?.duration, t)}
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1}>
            <SvgIcon
              fontSize="inherit"
              component={appointment?.capacity === 1 ? ProfileIcon : TeamIcon}
              inheritViewBox
              sx={{marginLeft: '10px'}}
            />
            <Typography variant="body2">
              {appointment?.capacity === 1 ? t('common.capacityTypeSingle') : t('common.capacityTypeMultiple')}
            </Typography>
          </Stack>
        </Stack>
        {
          appointment?.description &&
          <Box sx={{marginTop: '10px'}}>
            <TemplatePreview
              isBig={false}
              hideBackground
              noMargin
              fontSize={14}
              color={zoomPalette.gray[70]}
              body={appointment.description}
              seamless={true}
            />
          </Box>
        }
      </Box>
    </GlobalItemContainer>
  );
}
