import React, {useCallback, useEffect, useState} from 'react';

import {Box, Divider, Fade, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import GlobalLandingItem from './GlobalLandingItem';
import {AppointmentsListContainer, EventContainer, LoadingContainer, TypographyName} from './Style';
import {TypographyDescription} from './Style';


import {fetchGlobalLanding} from 'Api/Calendar';
import Avatar from 'Components/Avatar';
import ErrorDisplay from 'Components/MessageDisplay/ErrorDisplay';
import ZmSpinner from 'Components/ZmSpinner';
import {HttpError} from 'Error/HttpError';
import {fetchExternalUserInfo, get400ErrorReason} from 'Store/BookAppointmentsStore';
import {
  isCreateForTeamAppt,
  useQueryWithRetryCaptcha,
} from 'Utils';
import {getApptIntervalType} from 'Utils/apptAvailabilityUtils';

/**
 * Global Landing Page Component
 * @param {Object} props
 * @param {String} props.calendarId
 * @return {ReactElement}
 */
export default function GlobalLanding(props) {
  const {calendarId} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [isApptsLoaded, setIsApptsLoaded] = useState(false);

  const [isUserLoaded, setIsUserLoaded] = useState(false);

  const paramsValid = !!calendarId;

  const [appointments, setAppointments] = useState();
  const [name, setName] = useState('');
  const [errorState, setErrorState] = useState({
    isError: false,
    reason: '',
  });
  const errorInfo = {
    isError: (!paramsValid) || errorState.isError,
    reason: (!paramsValid) ? 'Invalid event identifiers' : errorState.reason,
  };

  const apptQuery = useCallback(
    async (calendarId) => {
      return await fetchGlobalLanding(calendarId, {
        orderBy: 'updated',
        timeMin: new Date().toISOString(),
      });
    }, []);

  const doFetchEvent = useQueryWithRetryCaptcha(apptQuery, calendarId);

  useEffect(() => {
    const fetchAppt = async () => {
      try {
        const data = await doFetchEvent(calendarId);
        const recurringAppts = data.items.reverse()
          .filter((item) =>
            getApptIntervalType(item) &&
            (!item.poolingType || isCreateForTeamAppt(item)) &&
            item.active !== false
          );
        setAppointments(recurringAppts);
      } catch (e) {
        if (e instanceof HttpError && e.errorCode === 400) {
          setErrorState({
            isError: true,
            reason: get400ErrorReason(e.body?.error?.message),
          });
        } else {
          setErrorState({
            isError: true,
            reason: e.message,
          });
        }
      } finally {
        setIsApptsLoaded(true);
      };
    };

    if (calendarId) {
      fetchAppt();
      dispatch(fetchExternalUserInfo(calendarId)).unwrap()
        .then((data) => {
          const name = data.displayName.value;
          // state.profilePicture = data['picture'];
          setName(name);
          setIsUserLoaded(true);
        }).catch((e) => {
          setErrorState({
            isError: true,
            reason: e.message,
          });
          setIsUserLoaded(true);
        });
    } else {
      setErrorState({
        isError: true,
        reason: 'Invalid event identifiers from Appointments',
      });
    }
  }, [calendarId, dispatch, doFetchEvent]);

  return (
    <Box
      sx={{
        width: '100vw',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <EventContainer>
        {(!isApptsLoaded || !isUserLoaded) ?
          (
            <LoadingContainer>
              <ZmSpinner absolute />
            </LoadingContainer>
          ) :
          (errorInfo.isError) ? (
            <ErrorDisplay errorInfo={errorInfo}/>
          ) :
            <>
              <Box
                sx ={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Avatar
                  boxStyle={{}}
                  nameStyle={{display: 'none'}}
                  displayName={name ?? ''}
                />
              </Box>
              <TypographyName align="center">
                {name}
              </TypographyName>
              <Divider sx={{marginTop: '12px'}}/>
              {
                appointments.length > 0 ?
                <>
                  <TypographyDescription align="center"
                  >
                    {t('landing.chooseBookingType', [name])}
                  </TypographyDescription>
                  <Fade in={true}>
                    <AppointmentsListContainer direction="row">
                      {(appointments.map((item) => (
                        <GlobalLandingItem
                          calendarId={calendarId}
                          appointment={item}
                          key={item.id}
                        />
                      )))}
                    </AppointmentsListContainer>
                  </Fade>
                </> :
                <>
                  <Typography align="center" sx={{
                    marginTop: '32px', fontWeight: 700, fontSize: '1rem', lineHeight: '1.5rem',
                  }}>
                    {t('landing.globalEmptyMsg')}
                  </Typography>
                  <Typography align="center" sx={{marginTop: '16px', fontSize: '1rem'}}>
                    {t('landing.globalEmptyMsgSub', [name])}
                  </Typography>
                </>
              }
            </>
        }
      </EventContainer>
    </Box>
  );
}

